import React from 'react';
import { Helmet } from 'react-helmet';

function MetaTags() {
  return (
    <Helmet>
      <title>Embrace Travel</title>
      <meta name="description" content="A page about my travels" />
      <meta name="keywords" content="travel, hotel, experiences" />
      <meta name="author" content="Katie Korzun" />
    </Helmet>
  );
}

export default MetaTags;

import React from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelope, faUser, faHotel, faHouse, faFishFins,
} from '@fortawesome/free-solid-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

function ContactItems() {
  return (
    <div className="contactItems">
      <a className="instagramLink" href="https://www.instagram.com/katie.travelsss?igsh=bWxpOGppamRuYm0=">
        <div className="contactItem">
          <FontAwesomeIcon icon={faInstagram} />
          &nbsp;
          @katie.travelsss
        </div>
      </a>
      <div className="contactItem">
        <FontAwesomeIcon icon={faEnvelope} />
        &nbsp;
        katie@embrace.travel
      </div>
      <Link to="/diving" className="contactItem" style={{ color: '#FFF', textDecoration: 'none' }}>
        <FontAwesomeIcon icon={faFishFins} />
        &nbsp;
        Diving Adventures
      </Link>
      <Link to="/hotel-reviews" className="contactItem" style={{ color: '#FFF', textDecoration: 'none' }}>
        <FontAwesomeIcon icon={faHotel} />
        &nbsp;
        Hotel Reviews
      </Link>
      <Link to="/" className="contactItem" style={{ color: '#FFF', textDecoration: 'none' }}>
        <FontAwesomeIcon icon={faHouse} />
        &nbsp;
        Home
      </Link>
      <Link to="/about-me" className="contactItem" style={{ color: '#FFF', textDecoration: 'none' }}>
        <FontAwesomeIcon icon={faUser} />
        &nbsp;
        About me
      </Link>
    </div>
  );
}

export default ContactItems;

import React from 'react';
import { H, Section } from 'react-headings';
import 'react-image-gallery/styles/css/image-gallery.css';
import { useMediaQuery } from 'react-responsive';

function AboutMePage() {
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' });

  return (
    <div className="boxWrapper">
      <div className="box" style={{ maxWidth: isMobile ? '90%' : '675px' }}>
        <Section component={<H>About Me</H>}>
          <div className="textWrap">
            Hello 😄 I&apos;m passionate about traveling, new cultures and languages.
            My goal is sharing unique experiences/less travelled destinations with,
            the world through the prism of my mind. I am budget concious as I am
            working class, so strive to travel as much as possible on a budget
            with the occassional luxury.
          </div>
          <br />
          <div className="textWrap">
            I love to challenge common beliefs and step outside of myself to understand
            the world better every day. I cherish kindness and would like to thank all
            you beautiful people ❤
          </div>
          <br />
        </Section>
      </div>
    </div>
  );
}

export default AboutMePage;

import React from 'react';

import AffiliateLinks from '../../components/AffiliateLinks';
import ThankYouText from '../../components/ThankYouText';

function AffiliatePage() {
  return (
    <>
      <AffiliateLinks />
      <ThankYouText />
    </>
  );
}

export default AffiliatePage;

import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import MetaTags from './components/MetaTags';
import AffiliatePage from './pages/AffiliatePage';
import HotelReviewsPage from './pages/HotelReviewsPage';
import DivingAdventuresPage from './pages/DivingAdventuresPage';
import AboutMePage from './pages/AboutMePage';
import Background from './components/Background';
import HeaderBar from './components/HeaderBar';
import './App.css';

function App() {
  return (
    <BrowserRouter>
      <MetaTags />
      <Background />
      <HeaderBar />
      <Routes>
        <Route path="/" element={<AffiliatePage />} />
        <Route path="/hotel-reviews" element={<HotelReviewsPage />} />
        <Route path="/diving" element={<DivingAdventuresPage />} />
        <Route path="/about-me" element={<AboutMePage />} />
      </Routes>
    </BrowserRouter>
  );
}
export default App;

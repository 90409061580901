import React from 'react';

import ProfilePicture from '../ProfilePicture';
import ContactItems from '../ContactItems';

function HeaderBar() {
  return (
    <>
      <ProfilePicture />
      <ContactItems />
      <br />
    </>
  );
}

export default HeaderBar;

import React from 'react';
import { H, Section } from 'react-headings';
import { InstagramEmbed } from 'react-social-media-embed';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMediaQuery } from 'react-responsive';
import {
  faFishFins,
} from '@fortawesome/free-solid-svg-icons';

function DivingAdventuresPage() {
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' });
  const images1 = [
    {
      original: './images/nightdive/nightdive_entry.jpg',
      thumbnail: './images/nightdive/nightdive_entry.jpg',
    },
    {
      original: './images/nightdive/nightdive_water.jpg',
      thumbnail: './images/nightdive/nightdive_water.jpg',
    },
    {
      original: './images/nightdive/nightdive_fish.jpg',
      thumbnail: './images/nightdive/nightdive_fish.jpg',
    },
    {
      original: './images/nightdive/nightdive_indianfish.jpg',
      thumbnail: './images/nightdive/nightdive_indianfish.jpg',
    },
    {
      original: './images/nightdive/nightdive_octopus.jpg',
      thumbnail: './images/nightdive/nightdive_octopus.jpg',
    },
    {
      original: './images/nightdive/nightdive_shark.jpg',
      thumbnail: './images/nightdive/nightdive_shark.jpg',
    },
    {
      original: './images/nightdive/nightdive_cuttlefish.jpg',
      thumbnail: './images/nightdive/nightdive_cuttlefish.jpg',
    },
  ];

  return (
    <div className="boxWrapper">
      <div className="box" style={{ maxWidth: isMobile ? '90%' : '675px' }}>
        <Section component={<H>Sydney Night Dive</H>}>
          <br />
          <ImageGallery items={images1} />
          <br />
          <div className="textWrap">
            📍 Bare Island, Sydney Australia (Shore Dive)
            <br />
            💲 Cost: $$
            <br />
          </div>
          <br />
          <br />
          <div className="textWrap">
            <b>Marine life (We saw):</b>
            <p className="bulletWrap">- Fiddler Ray</p>
            <p className="bulletWrap">- Eel tailed Catfish</p>
            <p className="bulletWrap">- Three Barred Porcupine Fish</p>
            <p className="bulletWrap">- Red Indian Fish</p>
            <p className="bulletWrap">- Common Sydney Octopus</p>
            <p className="bulletWrap">- Port Jackson Shark</p>
            <p className="bulletWrap">- Wobbegong Shark</p>
            <p className="bulletWrap">- Common Cuttlefish</p>
          </div>
          <br />
          <br />
          <div className="textWrap">
            This was my first independent dive after achieving my PADI open water with
            expedition dive. A night dive is especially exciting because you can no longer
            rely on your environment to give hints of where you are except when you are
            on the ocean floor. When the fish are asleep however you can really get up close and
            personal with them as they won&apos;t tend to flee, its really an amazing experience
            compared with daylight diving.
          </div>
          <br />
          <br />
          <div className="textWrap">
            Diving at night is a different experience compared to day diving, for one, different
            marine life will be present. When I was undertaking my openwater training
            dives in the same location I was able to see the famous Blue Groper, while at night
            I was able to see a red indian fish which is noctural. You will be able to see
            most shy animals at night such as sharks and Octopi and get up right close to them.
            In most cases they will be dazed so you can float along side them
            and enjoy them in their natural state.
          </div>
          <br />
          <br />
          <div className="textWrap">
            Night diving is an advanced diving skill and can induce a sense of vertigo due to the
            darkness, something my diving buddy experienced. At first I was a bit nervous but
            quickly acclimitised to the darkness as the sun set. You will get a powerful torch to
            help you gather your bearings and I found it re-assuring to be able to see
            where each diver was including my dive master Jason. Staring into the dark abyss can
            make you feel so small in the universe but awe inspiring at the same time. Some of
            the marine life are inconspicuously poisonous like the Eel tailed Catfish which
            Jason warned us about, so please be careful.
          </div>
          <br />
          <br />
          <div className="textWrap">
            The advantage a night dive, is that visibility is not such a concern,
            as most of the smaller animals you will be able to get right up and close.
            I haven&apos;t had much luck seeing an Octopus close up,
            but we were able to see one within a few feet of us.
            We also got to see the noctural and super rare red indian fish, it was almost alien
            looking and not fish like at all. Bare island has two sides one facing the ocean and
            one side facing the bay, often to avoid large currents one would dive on the bay side,
            however we were lucky enough to traverse both sides as the sea was calm.
            We managed to see the majority of the marine life on the ocean side but this could be
            because it was completely pitch black by the time we took our 2nd dive.
          </div>
          <br />
          <br />
          <div className="textWrap">
            You can get to bare island by driving, only about 20minutes from Sydney CBD.
            Public transport is available but will take nearly an hour from the CBD. You take
            the lightrail from central station to Kingsford and then take the 390X Bus. Uber
            is also available from Kingsford and will save you money by taking it here instead.
          </div>
          <br />
          <br />
          <div className="textWrap">
            With the expedition dive team you will be taken care of. Jason is very knowledgeable
            of the area and its marine life and has over 20 years of diving experience so you
            will be in capable hands.
          </div>
          <br />
          <br />
          <a className="link" href="https://expeditiondive.com.au/product/bare-island-day-and-night-dive/">
            <FontAwesomeIcon icon={faFishFins} />
          &nbsp;
            <b>Book a dive</b>
            {' '}
            - Expedition Dive Sydney
          </a>
        </Section>
        <br />
        <br />
        <br />
        <div className="innerBox">
          <InstagramEmbed url="https://www.instagram.com/reel/C5X_gimPLMS/" />
        </div>
        <br />
      </div>
    </div>
  );
}

export default DivingAdventuresPage;

import React from 'react';
import { H, Section } from 'react-headings';
import { InstagramEmbed } from 'react-social-media-embed';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMediaQuery } from 'react-responsive';
import {
  faHotel,
} from '@fortawesome/free-solid-svg-icons';

function HotelReviewsPage() {
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' });
  const images = [
    {
      original: './images/atlantis/atlantis_cloud9.jpg',
      thumbnail: './images/atlantis/atlantis_cloud9.jpg',
    },
    {
      original: './images/atlantis/atlantis_aquapark.jpg',
      thumbnail: './images/atlantis/atlantis_aquapark.jpg',
    },
    {
      original: './images/atlantis/atlantis_aquarium.jpg',
      thumbnail: './images/atlantis/atlantis_aquarium.jpg',
    },
    {
      original: './images/atlantis/atlantis_gastronomy.jpg',
      thumbnail: './images/atlantis/atlantis_gastronomy.jpg',
    },
    {
      original: './images/atlantis/atlantis_room.jpg',
      thumbnail: './images/atlantis/atlantis_room.jpg',
    },
  ];

  return (
    <div className="boxWrapper">
      <div className="box" style={{ maxWidth: isMobile ? '90%' : '675px' }}>
        <Section component={<H>Atlantis The Royal</H>}>
          <br />
          <ImageGallery items={images} />
          <br />
          <div className="textWrap">
            📍 Palm Jumeirah, Dubai
            <br />
            💲 Cost: $$$$
            <br />
          </div>
          <br />
          <div className="textWrap">
            We chose to stay because my partner decided it would be a great way to celebrate
            my birthday, This review will be based on the 2 days we stayed as it is well above
            our typical budget for a hotel and to maximise the use of the free ammenities.
            There is still alot to do for free and we definitely made use of that.
          </div>
          <br />
          <br />
          <div className="textWrap">
            <b>Hotel Inclusions (Freebies):</b>
            <p className="bulletWrap">- Unlimited free entry to Dubia Aquapark</p>
            <p className="bulletWrap">- Breakfast buffet at Gastronomy</p>
            <p className="bulletWrap">- Unlimited free entry to The Lost Chambers Aquarium</p>
            <p className="bulletWrap">- Unlimited free entry to Elements Sauna</p>
            <p className="bulletWrap">- Cloud 22 pool rooftop bar</p>
            <p className="bulletWrap">- Nightly Skyblaze Fountain, fire and Light show</p>
          </div>
          <br />
          <br />
          <div className="textWrap">
            Dubai Aquapark entry is included for hotel guests and can be used to get to the
            Lost Chambers Aquarium. Transport is easy with golf carts and comes frequently
            enough where usually just walking up to the station is convenient. One of the most
            famous slides is Shark Attack, which is a tube ride that traverses a giant Aquarium
            with Sharks. This ride was definitely an experience and I would recommend heading
            towards to this slide first as it is one of the park&apos;s flagship slides. Another
            slide is the leap of faith and is a near vertical drop that also passes
            through the Aquarium
            but its so quick that you won&apos;t really get a chance to see the sharks. This slide
            is one of the more exciting slides and the near vertical is definitely a
            surprise.
          </div>
          <br />
          <br />
          <div className="textWrap">
            The included breakfast buffet at Gastronomy was one of the best breakfast experiences
            I&apos;ve ever had at a hotel. From mediterranean, chinese, slavic, or just a
            delicious western breakfast, the choice is immense. The selections will satisfy
            vegetarians and carnivores alike. One standout was the Gravalax which is cured
            smoked salmon which came in every flavour,
            Wow!. We grazed on breakfast for 2.5 hours, theres no deadline to leave
            as breakfast just merges into lunch. This was simply an experience in itself and
            can&apos;t be compared.
          </div>
          <br />
          <br />
          <div className="textWrap">
            We also visited the Lost Chambers Aquarium and is also included, this was a nice place
            just to relax and stare at marine life. I wouldn&apos;t say this was the best Aquarium
            we&apos;ve seen and much smaller than the nearby Dubia Aquarium & underwater zoo.
            But if you have a spare hour or two its nice enough to just stroll around and relax.
            The aquarium is centred around a main cylindrical tank where if you wish you can
            pay extra to dive, there are smaller chambers and tanks housing fresh water and marine
            life that surround the central aquarium.
            You can get to the aquarium from the hotel via the golf cart located at the hotel
            entrance.
          </div>
          <br />
          <br />
          <div className="textWrap">
            The Sauna will delight sauna lovers but not something you have to exclusively see,
            however it was spacious and empty 2 hours before closing so worth checking out.
            The Cloud 22 pool needs an advanced reservation with the cheapest option needing
            450 AED per person. You will need a paid reservation in order to enter the pool.
            However the bar gives access to the same gorgious views and so we visited the bar twice
            to get that pefect instagram photo. The views are similar to the nearby Aura Skypool
            but at half the height and with less food selection.
          </div>
          <br />
          <br />
          <div className="textWrap">
            The nightly 7pm Skyblaze fountain show is spectacular, located just
            at the rear of the hotel. You will be treated to a clashing display of dancing fire
            infused with cascading water spirals. The show runs for about 15 minutes so worth a
            look if you are heading out for dinner around the time.
          </div>
          <br />
          <br />
          <div className="textWrap">
            Overall the hotel is more than just a hotel it is an experience that is well worth the
            higher than average price tag, especially if you are treating your significant other.
          </div>
          <br />
          <br />
          <a className="link" href="https://luxuryescapes.sjv.io/VmXXNk">
            <FontAwesomeIcon icon={faHotel} />
          &nbsp;
            <b>Book Atlantis</b>
            {' '}
            - Via LuxuryEscapes
          </a>
        </Section>
        <br />
        <br />
        <br />
        <div className="innerBox">
          <InstagramEmbed url="https://www.instagram.com/reel/C4PhGADSGAj/" />
        </div>
        <br />
      </div>
    </div>
  );
}

export default HotelReviewsPage;

import React from 'react';

function ThankYouText() {
  return (
    <div id="hashtag">
      ❤ Thank you for supporting me ❤
    </div>
  );
}

export default ThankYouText;

import React from 'react';

function ProfilePciture() {
  return (
    <a id="profilePicture" href="https://www.instagram.com/katie.travelsss?igsh=bWxpOGppamRuYm0=" target="_blank" rel="noopener noreferrer">
      <img src="./profile.jpg" alt="Profile" />
    </a>
  );
}

export default ProfilePciture;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCommentsDollar, faSimCard, faBed, faCoffee, faCreditCard,
} from '@fortawesome/free-solid-svg-icons';

function AffiliateLinks() {
  return (
    <div id="links">
      <a className="link" href="https://wise.com/invite/ihpc/katsiarynak153">
        <FontAwesomeIcon icon={faCreditCard} />
          &nbsp;
        <b>Wise</b>
        {' '}
        - Lowest travel card fees, use link to get a free card and get feeless $1000.
      </a>
      <a className="link" href="https://buymeacoffee.com/katie.travelsss">
        <FontAwesomeIcon icon={faCoffee} />
          &nbsp;
        <b>BuyMeACoffee</b>
        {' '}
        - Support me
      </a>
      <a className="link" href="https://etoro.tw/44VQsLc">
        <FontAwesomeIcon icon={faCommentsDollar} />
          &nbsp;
        <b>Etoro</b>
        {' '}
        - Online Stock Trading
        {' '}
        <b>(Free Gift)</b>
      </a>
      <a className="link" href="https://airalo.pxf.io/R5qV3v">
        <FontAwesomeIcon icon={faSimCard} />
          &nbsp;
        <b>Airalo</b>
        {' '}
        - Travel eSIM
      </a>
      <a className="link" href="https://www.trustedhousesitters.com/refer/RAF778952/?utm_source=copy-link&utm_medium=refer-a-friend&utm_campaign=refer-a-friend">
        <FontAwesomeIcon icon={faBed} />
          &nbsp;
        <b>TrustedHouseSitters</b>
        {' '}
        - Be or get a house sitter
        {' '}
        <b>(25% off)</b>
      </a>
    </div>
  );
}

export default AffiliateLinks;
